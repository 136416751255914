import { render, staticRenderFns } from "./RedeemManagementCreateScreen.vue?vue&type=template&id=ae558f80&scoped=true&"
import script from "./RedeemManagementCreateScreen.vue?vue&type=script&lang=js&"
export * from "./RedeemManagementCreateScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae558f80",
  null
  
)

export default component.exports